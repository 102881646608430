<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8273 6.47027C14.2117 6.04925 14.8697 6.0343 15.2729 6.43743L16.3787 7.54326C16.7692 7.93378 16.7692 8.56695 16.3787 8.95747L14.7502 10.586L12.3034 8.13922L13.8273 6.47027ZM10.9535 9.61771L5 16.1382V18.7504H6.58579L13.336 12.0002L10.9535 9.61771ZM16.6871 5.02322C15.4777 3.81384 13.5035 3.85868 12.3503 5.12173L3.26151 15.0761C3.09328 15.2604 3 15.5009 3 15.7504V19.7504C3 20.3027 3.44772 20.7504 4 20.7504H7C7.26522 20.7504 7.51957 20.645 7.70711 20.4575L17.7929 10.3717C18.9645 9.20012 18.9645 7.30062 17.7929 6.12905L16.6871 5.02322Z"
      :fill="getBaseColor(darkMode)"
    />
    <path
      d="M13 20H19"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
